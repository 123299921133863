const result_func = {
  changeHexToRgbA: (hex) => {
    let c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c = hex.substring(1).split('');
        if(c.length === 3){
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c>>16)&255, (c>>8)&255, c&255].join(',') + ',1)';
    }
    throw new Error('Bad Hex');
  },
  getGradinentStr: (arr) => {
    let result = "linear-gradient(90deg";
    const length = arr.length;

    for (let i = 0; i < length; i++) {
      if(arr[i].slice(0,4) !== "rgba") {
        return null;
      }

      result += `, ${arr[i]} ${((i / length) * 100).toFixed(0)}%, ${arr[i]} ${(((i + 1) / length) * 100).toFixed(0)}%`;
    }

    return result + `)`;
  }
}

const content = {
  모형택: {
    style: "#0171BB",
    title_top: "새로통합당 당대표",

    // ! important
    title: [
      "제5대 새로통합당 당대표",
      "모형택",
      "牟泂擇 | Mo Hyeong-taek",
    ],

    title_style: [
      "#0B1725",
      "#0171BB",
      "#EB1191",
    ],

    // ! important
    img: "mo.png",
    
    // todo - index
    content: {
      a출생: [`&(1960년 1월 29일&) (63세)`, `&(경기도 용선군 포정면&)|(現 &(경기도 용선시 추영구 포정읍&))`],
      거주지: [`&(서울시 종로구 인경동&)`],
      본관: [`&(형주 모씨&)(邢州 牟氏)`],
      가족: [`배우자 이현숙(60), 자녀 모수린(32)`],
      학력: [`&(한국대학교 법과대학&) 졸업(재학 중 &(사법고시&) 합격)`],
      현직: [
        `&(새로통합당&) 상임고문`,
        `&(우리사회함께복지모금회&) 명예회장`,
        `사단법인 &(청년미래희망더하기&) 고문위원`,
      ],
      b주요경력: [
        `1990.09~1992.09|&(주일지방검찰청&) 순흥지청 &(검사&)`,
        `1992.09~1994.09|&(연수지방검찰청&) &(검사&)`,
        `1994.09~1996.09|&(홍인지방검찰청&) &(검사&)`,
        `1996.09~1998.09|&(청우지방검찰청&) 월영지청 &(검사&)`,
        `1998.09~2001.05|&(법무부&) 국제법무과 근무`,
        `2001.05~2001.12|&(황영진 게이트&) 특검팀 특별수사관 `,
        `2002.01~2004.05|&(서울지방검찰청&) 금융조세조사 1부 &(검사&) `,
        `2004.05~2006.05|&(부한지방검찰청&) 양남지청 &(검사&) `,
        `2006.05~2008.05|&(서울지방검찰청&) `,
        `2008.05~2010.05|&(무언지방검찰청&) &(검사&)`,
        `2010.05~2011.12|&(법무부&) 법조인력정책과 과장 `,
        `2012.04|제19대 &(국회의원&) (&(서울특별시&) &(강하구&) 갑/&(새국민당&))`,
        `2013.02|&(새국민당&) 혁신위원회 위원장`,
        `2014.04|&(새국민당&) &(서울특별시&) 비례대표 공천심사위원회 위원장`,
        `2015.12|&(새국민당&) 상임고문`,
        `2017.03|&(새로통합당&) &(무언시&) 남구을 당협위원장`,
        `2020.02|&(제21대 국회의원&) (&(강원도&) &(무언시&) 무언구 갑/&(새로통합당&))`,
        `2022.08|제5대 &(새로통합당&) 당대표`,
      ],
      c서명: [`mo_sign.png`],
      병역: [`&(면제&) (&(질병&),&(부동시&))`],
      종교: [`&(개신교&)`],
    },

    index: [
      `개요`,
      `약력`,
      `!생애`,
      `선거 이력`,
      `소속 정당`,
      `가족 관계`,
      `둘러보기`,
    ]
  }
}

export {
  result_func,
  content
}