import './Result.css';

import { useEffect, useState } from 'react';

import { result_func, content } from '../../common/content';
import ResultBlock from './ResultComps/ResultBlock';

function Result({ pageId }) {
  const [con, setCon] = useState(null);
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    if(!con && content[pageId]) {
     setCon(JSON.parse(JSON.stringify(content[pageId])));
    }

    if(con) {
      setIsLoad(true);
      document.title = `${pageId} - 자두위키`;
    }
  },[pageId, con]);

  return (
    <div className='Result'>
      {
        isLoad ?
        <>
          <div className='Info' style={con.style ? {borderColor: con.style} : {}}>
            {
              con.title_top ?
              <div className='title_top mid' style={con.style ? {backgroundColor: con.style} : {}}>
                {con.title_top}
              </div>
              :
              <></>
            }

            <div className='title mid' style={con.title_style ?
              {
                background: result_func.getGradinentStr(
                  con.title_style.map(e => result_func.changeHexToRgbA(e))
                )
              }
              :
              {}
            }>
              {
                con.title.map((e,i) => {
                  return (
                    <div key={`result_info_title_${i}`} className={`t${i + 1}`}>{e}</div>
                  )
                })
              }
            </div>
            
            <div className='img'>
              <img src={`/images/${con.img}`} alt='main_img' />
            </div>

            <ResultBlock obj={con.content} />
          </div>

          <div className='Index'>
            <div className='title'>목차</div>
            {
              con.index.map((e,i1) => {
                return (
                  <div className='box' key={`temp_index_title_${i1}`}>
                    {/* todo case : arr -> list */}
                    <span className='s1'>{i1 + 1 + "."}</span>
                    <span> </span>
                    {
                      e[0] === "!" ?
                      <span className='s2 s_hyper'>{e.slice(1)}</span>
                      :
                      <span className='s2'>{e}</span>
                    }
                  </div>
                )
              })
            }
          </div>

          <div className='Body'>
            {
              con.index.map((e,i1) => {
                return (
                  <div className='box folding' key={`temp_titles_key_${i1}`}>
                    <span className='arrow'>→</span>
                    <span className='s1'>{i1 + 1 + "."}</span>
                    <span> </span>
                    {
                      e[0] === "!" ?
                      <span className='s2 fBlank s_hyper'>{e.slice(1)}</span>
                      :
                      <span className='s2 fBlank'>{e}</span>
                    }
                    <span className='s3'>{`[편집]`}</span>
                  </div>
                )
              })
            }
          </div>
        </>
        :
        <>
          해당 문서를 찾을 수 없습니다.
        </>
      }
    </div>
  );
}

export default Result;