import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

function ResultBlock({ obj }) {
  const GetInnerT = ({ cName, value }) => {
    const idName = uuidv4();

    useEffect(() => {
      document.getElementById(idName).innerHTML = value
        .replaceAll('&(', '<span class="result_hyper_span">').replaceAll('&)','</span>');
    });

    return (
      <div
        className={cName}
        id={idName}
      >
      </div>
    )
  }


  return (
    <>
      {
        Object.keys(obj).map((obj_key, obj_i) => {
          const obj_value = obj[obj_key];

          return (
            <div key={`result_block_key_${obj_i}`} className={Object.keys(obj).length - 1 === obj_i ? "block end" : "block"}>
              {
                obj_key[0] === 'a' ?
                // todo - a출생
                <>
                  <div className='tag'>
                    {obj_key.slice(1)}
                  </div>

                  <div className='text text_nop'>
                    {
                      obj_value.map((e,i) => {
                        const arr = e.split('|');

                        if(arr.length < 2) {
                          return (
                            <GetInnerT
                              key={`result_block_${obj_key}_key_${i}`}
                              cName={obj_value.length - 1 === i ? 't ss' : 't ss bb'}
                              value={arr[0]}
                            />
                          )
                        } else {
                          return (
                            <div
                              key={`result_block_${obj_key}_key_${i}`}
                              className={obj_value.length - 1 === i ? 't ss' : 't ss bb'}
                            >
                              {
                                arr.map((ae,ai) => {
                                  return (
                                    <GetInnerT
                                      key={`result_block_${obj_key}_key_${i}_${ai}`}
                                      cName={'t'}
                                      value={ae}
                                    />
                                  )
                                })
                              }
                            </div>
                          )
                        }
                      })
                    }
                  </div>
                </>
                :
                // todo - longArr + comment
                obj_key[0] === 'b' ?
                <>
                  <div className='tag'>
                    {obj_key.slice(1)}
                  </div>
                  <div className='text'>
                    {
                      obj_value.map((e,i) => {
                        const arr = e.split("|");

                        return (
                          <GetInnerT
                            key={`temp_content_${i}`}
                            cName={'t'}
                            value={`${arr[1]}<span class='y'>(${arr[0]})</span>`}
                          />
                        )
                      })
                    }
                </div>
                </>
                :
                // todo - image
                obj_key[0] === 'c' ?
                <>
                  <div className='tag'>
                    {obj_key.slice(1)}
                  </div>
                  <div className='text'>
                    <div className='i'>
                      <img src={'/images/' + obj_value} alt='sign' />
                    </div>
                  </div>
                </>
                :
                // todo - basic
                <>
                  <div className='tag'>
                    {obj_key}
                  </div>

                  <div className='text'>
                    {
                      obj_value.map((e,i) => {
                        return (
                          <GetInnerT
                            key={`result_block_${obj_key}_key_${i}`}
                            cName={'t'}
                            value={e}
                          />
                        )
                      })
                    }
                  </div>
                </>
              }
            </div>
          )
        })
      }
    </>
  );
}

export default ResultBlock;