import './Footer.css';

function Footer() {
  let nowBotScroll = false;
  let nowTopScorll = false;

  function scrollToTop() {
    if( nowBotScroll ) {
      return;
    }

    const position = document.documentElement.scrollTop;

    if (position) {
      nowTopScorll = true;
      window.requestAnimationFrame(() => {
        window.scrollTo(0, position - position / 20);
        scrollToTop();
      });
    } else {
      nowTopScorll = false;
    }
  }

  function scrollToBot() {
    if( nowTopScorll ) {
      return;
    }

    const position = document.documentElement.scrollTop;

    if (position < document.body.scrollHeight - window.innerHeight) {
      nowBotScroll = true;
      window.requestAnimationFrame(() => {
        if (position < 1) {
          window.scrollTo(0, 200);
        } else {
          window.scrollTo(0, position + position / 20);
        }
        scrollToBot();
      });
    } else {
      nowBotScroll = false;
    }
  }

  return (
    <div className='Footer'>
      <img src='/images/footer/ccbync.png' alt='footer_cc' />
      <div>이 저작물은 <span style={{color: "var(--color-hyper)", cursor: "pointer"}}>CC BY-NC-SA 2.0 KR</span>에 따라 이용할 수 있습니다. (단, 라이선스가 명시된 일부 문서 및 삽화 제외)</div>
      <div>기여하신 문서의 저작권은 각 기여자에게 있으며, 각 기여자는 기여하신 부분의 저작권을 갖습니다.</div>
      <p></p>
      <div>자두위키는 백과사전이 아니며 검증되지 않았거나, 편향적이거나, 잘못된 서술이 있을 수 있습니다.</div>
      <div>자두위키는 위키위키입니다. 여러분이 직접 문서를 고칠 수 있으며, 다른 사람의 의견을 원할 경우 직접 토론을 발제할 수 있습니다.</div>

      <div id='navi_btn'>
        <div id='top' onClick={scrollToTop}>
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
            <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z">
            </path>
          </svg>
        </div>
        <div id='bot' onClick={scrollToBot}>
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
            <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z">
            </path>
          </svg>
        </div>
      </div>
    </div>
  );
}

export default Footer;