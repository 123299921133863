import './App.css';

import { useParams } from 'react-router-dom';
// import { icons } from './common/IconsFunc';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ResultTitle from './components/Result/ResultComps/ResultTitle';
import Result from './components/Result/Result';

function App() {
  const { pageId } = useParams();

  return (
    <div className="App">
      <Header />

      <ResultTitle pageId={pageId}/>

      <Result pageId={pageId} />

      <Footer />
    </div>
  );
}

export default App;